<template>
  <b-modal
      id="list-record-catch-gallery-modal"
      ref="list-record-catch-gallery-modal"
      hide-footer
      centered
      title="Képek megtekintése"
  >
    <b-overlay :show="loading">
      <b-carousel
          id="carousel-interval"
          controls
          indicators
      >
        <b-carousel-slide v-for="image in galleryData" :key="image.image_id" :img-src="image.publicUrl"/>
      </b-carousel>
    </b-overlay>
  </b-modal>
</template>

<script>

import {BCarousel, BCarouselSlide, BOverlay } from 'bootstrap-vue';

export default {
  props: {
    entityId: {
      required: true
    }
  },
  components: {
    BCarousel,
    BCarouselSlide,
    BOverlay
  },
  data() {
    return {
      galleryData: [],
      loading: false
    }
  },
  methods: {
    initGallery() {

      this.galleryData = []
      this.loading = true

      this.$store.dispatch('getImagesForEntity', {
        id: this.entityId,
        type: 'record-catch'
      }).then(async response => {
        let tmp = []

        Object.keys(response.data.images).forEach(key => {
          tmp.push(response.data.images[key])
        })

        this.galleryData = tmp

      }).catch(error => {
        if (error.response.status === 422) {
          this.$refs.simpleRules.setErrors(error.response.data.errors)
        } else {
          this.$helpers.showErrorToast(error.toString())
        }
      }).finally(() => {
        this.loading = false
      })
    }
  },
  watch: {
    entityId: {
      handler() {
        this.initGallery()
      }
    }
  },
}
</script>
