<template>
    <div>
        <div class="custom-search d-flex justify-content-end">
            <div v-if="$acl.canCreatePermission(role)" class="float-left mr-auto">
                <b-button
                        variant="primary"
                        @click="newEntity"
                >
                    <span class="text-nowrap">Hozzáadás</span>
                </b-button>
            </div>
            <b-form-group>
                <div class="d-flex align-items-center">
                    <label class="mr-1">Keresés</label>
                    <b-form-input
                            v-model="searchTerm"
                            placeholder=" ..."
                            type="text"
                            class="d-inline-block"
                            @input="globalSearch"
                    />
                </div>
            </b-form-group>
        </div>
        <b-overlay
                :show="loading"
                spinner-variant="primary"
                spinner-type="grow"
                rounded="sm"
        >
            <!-- table -->
            <vue-good-table
                    mode="remote"
                    :columns="columns"
                    :rows="rows"
                    :rtl="direction" íí
                    :pagination-options="{
          enabled: true,
          perPage: pageLength
        }"
                    :sort-options="{
          enabled: true,
          initialSortBy: sortBy
        }"
                    :row-style-class="rowStyleClassFn"
                    @on-row-click="onRowClick"
                    @on-sort-change="onSortChange"
                    @on-column-filter="onColumnFilter"
            >
                <div slot="emptystate">
                    <div class="vgt-center-align vgt-text-disabled">
                        {{ loading ? 'Adatok betöltése ...' : $t('table.list.empty') }}
                    </div>
                </div>
                <template
                        slot="table-row"
                        slot-scope="props"
                >

                    <!-- Column: Name -->
                    <span
                            v-if="props.column.field === 'fisherman_nationality'"
                            class="text-nowrap"
                    >
                <span class="text-nowrap" v-if="props.row.fisherman_nationality">{{
                    nationalities.getName(props.row.fisherman_nationality, "hu")
                    }} ({{ props.row.fisherman_nationality }})</span>
                <span class="text-nowrap" v-else>-</span>
              </span>

                    <span
                            v-else-if="props.column.field === 'weight'"
                            class="text-nowrap"
                    >
                <span class="text-nowrap">{{ props.row.weight }} kg</span>
              </span>

                    <span
                            v-else-if="props.column.field === 'length'"
                            class="text-nowrap"
                    >
                <span class="text-nowrap">{{ props.row.length ? props.row.length + ' cm' : '-' }}</span>
              </span>

                    <span
                            v-else-if="props.column.field === 'perimeter'"
                            class="text-nowrap"
                    >
                <span class="text-nowrap">{{ props.row.perimeter ? props.row.perimeter + ' cm' : '-' }}</span>
              </span>

                    <span
                            v-else-if="props.column.field === 'catch_time'"
                            class="text-nowrap"
                    >
                <span v-if="props.row.only_year" class="text-nowrap">{{ props.row.catch_time.substr(0, 4) }}</span>
                <span v-else class="text-nowrap">{{ props.row.catch_time }}</span>
              </span>

                    <span
                            v-else-if="props.column.field === 'released'"
                            class="text-nowrap"
                    >
                <span class="text-nowrap">{{ props.row.released ? 'Igen' : 'Nem' }}</span>
              </span>

                    <span
                            v-else-if="props.column.field === 'report'"
                            class="text-nowrap"
                    >
                <span class="text-nowrap" v-html="props.row.report"></span>
              </span>

                    <span
                            v-else-if="props.column.field === 'hidden_photos'"
                            class="text-nowrap"
                    >
                <span class="text-nowrap">{{ props.row.hidden_photos ? 'Igen' : 'Nem' }}</span>
              </span>

                    <span
                            v-else-if="props.column.field === 'pr_photos'"
                            class="text-nowrap"
                    >
                <span class="text-nowrap">{{ props.row.pr_photos ? 'Igen' : 'Nem' }}</span>
              </span>

                    <!-- Column: Action -->
                    <span v-else-if="props.column.field === 'action'">
              <span v-if="props.row.mainImage">
                  <b-button
                          size="sm"
                          variant="info"
                          v-b-modal.list-record-catch-gallery-modal
                          @click.stop="showGallery(props.row.id)"
                  >
                    <feather-icon
                            icon="ImageIcon"
                    />
                  </b-button>
                </span>
                <span v-if="$acl.canEditPermission(role)">
                  <b-button
                          size="sm"
                          variant="primary"
                          class="ml-1"
                          @click.stop="$router.push({ name: 'modify-record-catch', params: { id: props.row.id }})"
                  >
                    <feather-icon
                            icon="EditIcon"
                    />
                  </b-button>
                </span>
                <span v-if="$acl.canDeletePermission(role)">
                  <b-button
                          size="sm"
                          variant="danger"
                          class="ml-1"
                          @click.stop="deleteEntity(props.row.id, $event)"
                  >
                    <feather-icon
                            icon="XCircleIcon"
                    />
                  </b-button>
                </span>
              </span>

                    <!-- Column: Common -->
                    <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
                </template>

                <!-- pagination -->
                <template
                        slot="pagination-bottom"
                        slot-scope="props"
                >
                    <div class="d-flex justify-content-between flex-wrap">
                        <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              {{ ((page - 1) * pageLength) + 1 }} +
            </span>
                            <b-form-select
                                    v-model="pageLength"
                                    :options="pages"
                                    class="mx-1"
                                    @change="handlePageChange"
                            />
                            <span class="text-nowrap "> a {{ dataFromServer.total }} találatból </span>
                        </div>
                        <div>
                            <b-pagination
                                    :value="1"
                                    :total-rows="dataFromServer.total"
                                    :per-page="dataFromServer.per_page"
                                    first-number
                                    last-number
                                    align="right"
                                    prev-class="prev-item"
                                    next-class="next-item"
                                    class="mt-1 mb-0"
                                    @change="handleChangePage"
                            >
                                <template #prev-text>
                                    <feather-icon
                                            icon="ChevronLeftIcon"
                                            size="18"
                                    />
                                </template>
                                <template #next-text>
                                    <feather-icon
                                            icon="ChevronRightIcon"
                                            size="18"
                                    />
                                </template>
                            </b-pagination>
                        </div>
                    </div>
                </template>
            </vue-good-table>
        </b-overlay>
        <gallery :entity-id="showGalleryId"></gallery>
        <span v-html="css"></span>
    </div>
</template>

<script>
import {
    BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BButton, BOverlay
} from 'bootstrap-vue'
import {VueGoodTable} from 'vue-good-table'
import store from '@/store/index'
import gallery from "@/views/pages/_components/_modal/record-catch/gallery";

export default {
    components: {
        VueGoodTable,
        BAvatar,
        BBadge,
        BPagination,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BDropdown,
        BDropdownItem,
        BButton,
        BOverlay,
        gallery
    },
    data() {
        return {
            css: '',
            role: 'record_catch',
            loading: false,
            pageLength: 10,
            dir: false,
            pages: ['10', '20', '50'],
            columns: [
                {
                    label: this.$t('form.id'),
                    field: 'id',
                    sortable: true,
                    filterOptions: {
                        enabled: true,
                        placeholder: 'Az. szerinti szűrés',
                    },
                    width: '5%',
                },
                {
                    label: 'Halfaj',
                    field: 'fish_type_name',
                    sortable: true,
                    filterOptions: {
                        enabled: true,
                        placeholder: 'Halfaj szerinti szűrés',
                    },
                    width: '5%',
                },
                {
                    label: 'Horgász',
                    field: 'fisherman_name',
                    sortable: true,
                    filterOptions: {
                        enabled: true,
                        placeholder: 'Horgász szerinti szűrés',
                    },
                    width: '5%',
                },
                {
                    label: 'Nemzet',
                    field: 'fisherman_nationality',
                    sortable: true,
                    filterOptions: {
                        enabled: true,
                        placeholder: 'Nemzet szerinti szűrés',
                    },
                    width: '5%',
                },
                {
                    label: 'Tömeg',
                    field: 'weight',
                    sortable: true,
                    filterOptions: {
                        enabled: true,
                        placeholder: 'Tömeg szerinti szűrés',
                    },
                    width: '5%',
                },
                {
                    label: 'Hossz',
                    field: 'length',
                    sortable: true,
                    filterOptions: {
                        enabled: true,
                        placeholder: 'Hossz szerinti szűrés',
                    },
                    width: '5%',
                },
                {
                    label: 'Kerület',
                    field: 'perimeter',
                    sortable: true,
                    filterOptions: {
                        enabled: true,
                        placeholder: 'Kerület szerinti szűrés',
                    },
                    width: '5%',
                },
                {
                    label: 'Fogás időpontja',
                    field: 'catch_time',
                    sortable: true,
                    filterOptions: {
                        enabled: true,
                        placeholder: 'Fogás időpontja szerinti szűrés',
                    },
                    width: '5%',
                },
                {
                    label: 'Csali',
                    field: 'bait_name',
                    sortable: true,
                    filterOptions: {
                        enabled: true,
                        placeholder: 'Csali szerinti szűrés',
                    },
                    width: '5%',
                },
                {
                    label: 'Visszaengedés',
                    field: 'released',
                    sortable: true,
                    filterOptions: {
                        enabled: true,
                        placeholder: 'Visszaengedés szerinti szűrés',
                        filterDropdownItems: [
                            {value: true, text: 'Igen'},
                            {value: false, text: 'Nem'}
                        ]
                    },
                    width: '5%',
                },
                {
                    label: 'Beszámoló',
                    field: 'report',
                    sortable: true,
                    filterOptions: {
                        enabled: true,
                        placeholder: 'Beszámoló szerinti szűrés',
                    },
                    width: '5%',
                },
                {
                    label: 'Verseny',
                    field: 'competition',
                    sortable: true,
                    filterOptions: {
                        enabled: true,
                        placeholder: 'Verseny szerinti szűrés',
                    },
                    width: '5%',
                },
                {
                    label: 'Tiltott fotó',
                    field: 'hidden_photos',
                    sortable: true,
                    filterOptions: {
                        enabled: true,
                        placeholder: 'Tiltott fotó szerinti szűrés',
                        filterDropdownItems: [
                            {value: true, text: 'Igen'},
                            {value: false, text: 'Nem'}
                        ]
                    },
                    width: '5%',
                },
                {
                    label: 'PR fotó',
                    field: 'pr_photos',
                    sortable: true,
                    filterOptions: {
                        enabled: true,
                        placeholder: 'PR fotó szerinti szűrés',
                        filterDropdownItems: [
                            {value: true, text: 'Igen'},
                            {value: false, text: 'Nem'}
                        ]
                    },
                    width: '5%',
                },
            ],
            showGalleryId: '',
            rows: [],
            page: 1,
            dataFromServer: [],
            filterData: [],
            searchTerm: '',
            sortBy: {
                field: 'catch_time',
                type: 'desc',
            },
            columnFilters: [],
            nationalities: '',
        }
    },
    computed: {
        direction() {
            if (store.state.appConfig.isRTL) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.dir = true
                return this.dir
            }
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.dir = false
            return this.dir
        },
    },
    created() {
        let storedPageLength = localStorage.getItem('pageLength')
        if (storedPageLength) {
            this.pageLength = storedPageLength
        }

        let nationalities = require("i18n-iso-countries")
        nationalities.registerLocale(require("i18n-iso-countries/langs/hu.json"));
        this.nationalities = nationalities

        if (this.$acl.canEditPermission(this.role) || this.$acl.canDeletePermission(this.role)) {
            const actions = [
                {
                    label: this.$t('table.header.actions'),
                    field: 'action',
                    sortable: false,
                    width: '15rem',
                },
            ]

            this.columns = [...this.columns, ...actions]
        }

        this.$store.dispatch('fetchFishTypes', {limit: 100000}).then(response => {
            this.css = '<style>';
            Object.keys(response.data.entities.data).forEach(key => {
                this.css += '.color-' + response.data.entities.data[key].color.replace('#', '') + '{ background-color: ' + response.data.entities.data[key].color + ' }';
                this.css += 'table.vgt-table tr.color-' + response.data.entities.data[key].color.replace('#', '') + ':hover{ background-color: ' + response.data.entities.data[key].color + ' !important }';
            })
        }).finally(() => {
            this.css += '</style>'
        })

        this.checkUrlFilter()
        this.initEntities()
    },
    methods: {
        initEntities() {
            this.loading = true
            const searchParams = {
                page: parseInt(this.page),
                limit: parseInt(this.pageLength),
                query: this.searchTerm,
                columnFilters: this.columnFilters,
                sort: this.sortBy,
            }

            this.$store.dispatch('fetchRecordCatches', searchParams).then(response => {
                this.rows = response.data.entities.data
                this.dataFromServer = response.data.entities
                this.page = this.dataFromServer.current_page
                this.pageLength = parseInt(this.dataFromServer.per_page)
            }).finally(() => {
                this.loading = false
            })
        },
        showGallery(id) {
            this.showGalleryId = id
        },
        onRowClick(params) {
            if (this.$acl.canEditPermission(this.role))
                this.$router.push({name: 'modify-record-catch', params: {id: params.row.id}})
        },
        handleChangePage(page) {
            this.page = page
            this.initEntities()
        },
        handlePageChange(active) {
            this.pageLength = active
            localStorage.setItem('pageLength', active)
            this.page = 1
            this.handleChangePage()
        },
        newEntity() {
            this.$router.push({name: 'add-record-catch'})
        },
        deleteEntity(id, event) {
            this.$bvModal
                .msgBoxConfirm('Biztosan törölni szeretnéd?', {
                    title: 'Jóváhagyás',
                    size: 'sm',
                    okVariant: 'primary',
                    okTitle: 'Igen',
                    cancelTitle: 'Nem',
                    cancelVariant: 'outline-secondary',
                    hideHeaderClose: false,
                    centered: true,
                })
                .then(value => {
                    if (value) {
                        // axios
                        this.$store.dispatch('deleteRecordCatch', id).then(() => {
                            this.initEntities(1)
                            this.$helpers.showSuccessToast(`Sikeres törlés (${id})`)
                        })
                    }
                })
        },
        globalSearch(params) {
            this.page = 1
            this.query = params.searchTerm
            this.initEntities()
        },
        onSortChange(params) {
            this.page = 1
            this.sortBy = params[0]
            this.initEntities()
        },
        onColumnFilter(params) {
            this.page = 1
            this.columnFilters = params.columnFilters
            this.initEntities()
        },
        checkUrlFilter() {
            if (typeof this.$route.query.fisherman_id !== "undefined") {
                this.columnFilters = {
                    fisherman_id: this.$route.query.fisherman_id
                }
            }
            if (typeof this.$route.query.fish_category_id !== "undefined") {
                this.columnFilters = {
                    fish_category_id: this.$route.query.fish_category_id
                }
            }
            if (typeof this.$route.query.fish_type_id !== "undefined") {
                this.columnFilters = {
                    fish_type_id: this.$route.query.fish_type_id
                }
            }
            if (typeof this.$route.query.location_id !== "undefined") {
                this.columnFilters = {
                    location_id: this.$route.query.location_id
                }
            }
            if (typeof this.$route.query.bait_id !== "undefined") {
                this.columnFilters = {
                    bait_id: this.$route.query.bait_id
                }
            }
        },
        rowStyleClassFn(row) {
            return 'color-' + row.fish_type_color.replace('#', '')
        }
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
